/* src/components/BenefitsSection.css */
.benefits {
    display: flex;
    justify-content: center;
    padding: 60px 20px;
    background-color: #fff;
    gap: 30px;
  }
  
  .benefit-card {
    background: #ff6b6b;
    padding: 20px;
    color: white;
    border-radius: 8px;
    text-align: center;
    width: 200px;
  }
  
  .benefit-card h3 {
    margin-bottom: 10px;
  }
  