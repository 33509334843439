/* src/pages/SubscriptionOptionsPage.css */
.subscription-container {
  text-align: center;
  padding: 2rem;
  background-color: #f7f7f7;
}

.subscription-heading {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.subscription-cards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.card {
  background-color: #ffffff;
  padding: 2rem;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.premium {
  border: 2px solid #ff8c00;
}

.card h3 {
  font-size: 1.8rem;
  color: #555;
}

.price {
  font-size: 1.5rem;
  color: #444;
  margin: 1rem 0;
}

.benefits-list {
  list-style: none;
  padding: 0;
  font-size: 1rem;
  color: #666;
  margin: 1rem 0;
}

.benefits-list li {
  margin: 0.5rem 0;
}

.subscribe-btn {
  background-color: #ff8c00;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-btn:hover {
  background-color: #e07a00;
}

.payment-options {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
}

.payment-options h3 {
  font-size: 1.8rem;
  color: #555;
  margin-bottom: 1rem;
}

.payment-methods {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.payment-option {
  background-color: #f0f0f0;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-option:hover {
  background-color: #ddd;
}
