/* src/pages/ShopPage.css */
.shop-container {
    padding: 2rem;
    text-align: center;
    background-color: #f7f7f7;
  }
  
  .shop-heading {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .shop-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .shop-item {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .shop-item:hover {
    transform: scale(1.05);
  }
  
  .item-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .item-name {
    font-size: 1.2rem;
    margin: 0.5rem 0;
    color: #444;
  }
  
  .item-price {
    font-size: 1rem;
    color: #666;
  }
  
  .add-to-cart-btn {
    margin-top: 1rem;
    background-color: #ff8c00;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart-btn:hover {
    background-color: #e07a00;
  }
  