/* src/components/HeroSection.css */

.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #fff;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4; /* Adjust for a lighter fade */
  z-index: -1;
}

.content-overlay {
  position: relative;
  text-align: center;
  z-index: 1;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Optional dark overlay for text readability */
  border-radius: 8px;
}

h2 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

p {
  font-size: 1.2em;
  margin-bottom: 1em;
}
