/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #fff;
  }
  
  .logo {
    font-size: 1.8em;
    color: #333;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
  
  .login-btn {
    padding: 10px 20px;
    border: none;
    background: #ff6b6b;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  