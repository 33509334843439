/* src/components/ProgressBar.css */
.progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    height: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .progress {
    height: 100%;
    background-color: #ff6b6b;
    border-radius: 5px;
    transition: width 0.3s ease;
  }
  