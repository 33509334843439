/* src/pages/StyleQuizPage.css */
.quiz-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
  }
  
  .option-button {
    padding: 10px;
    border: 1px solid #ff6b6b;
    background-color: #fff;
    color: #ff6b6b;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .option-button:hover {
    background-color: #ff6b6b;
    color: white;
  }
  
  .next-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #ff6b6b;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1em;
  }
  